import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DapiService} from '../../services/dapi.service';
import { Router,ActivatedRoute,ParamMap } from '@angular/router';
import {Location} from '@angular/common';

@Component({
  selector: 'app-error-model',
  templateUrl: './error-model.component.html',
  styleUrls: ['./error-model.component.scss']
})
export class ErrorModelComponent {
  public token:string;
  public dialogData :any;
  public invalid :any;
  constructor(
    private _route: ActivatedRoute,
    public dapiService:DapiService,
    public router: Router,
    private _location: Location,
    public dialogRef: MatDialogRef<ErrorModelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
     this.dialogData = data;
     this.invalid = data;
  }

  onNoClick(): void {
    this.dialogRef.close(false);
    //this._location.back();
  }

  onBackClick(type:String): void {
    this.dialogRef.close(type);
    //this._location.back();
  }
  cancel(): void {
    this.dialogRef.close(false);
    // this._location.back();
  }
}
