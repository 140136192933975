import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { DapiService } from 'src/app/services/dapi.service';
import { DapiDataService } from 'src/app/services/dapi-data.service';
import { DapiModalComponent } from '../dapi-modal/dapi-modal.component';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ErrorModelComponent } from '../error-model/error-model.component';

@Component({
  selector: 'app-transfer-fund',
  templateUrl: './transfer-fund.component.html',
  styleUrls: ['./transfer-fund.component.scss']
})
export class TransferFundComponent implements OnInit {
  public token:string;
  public bank_id:string;
  public bankAccounts:any=[];
  public enterOtp:any;
  public errorDetails :any;
  public dapiErrorDetails:any;
  public isLoaded:boolean=true;
  public inputData:any;
  public inputField:any;
  constructor(private router:Router, private _route: ActivatedRoute,
              public dapiService:DapiService,
              public dapiDataService:DapiDataService,
              public dialog: MatDialog) { }

  ngOnInit() {
    this.isLoaded=true;
    this._route.paramMap.subscribe((params: ParamMap) => {
      this.token = params.get('auth_token')
      this.inputData = params.get('token')
      if(this.inputData){
        this.inputField = JSON.parse(atob(params.get('token')))
      }else{
        this.inputField={};
      }
      this.bank_id = params.get('bankId')
      this.inputField.note=  this.inputField.note ? this.inputField.note : "";
            if (this.inputField.note.length > 500) {
              this.inputField.note = this.inputField.note.substring(0, 500);
            }
      this.getBankAccounts(this.token,this.bank_id)
    })
  }

  getBankAccounts(auth_token,bank_id){
      this.dapiService.getDapiBankListByID(bank_id,auth_token).subscribe((bankAccounts)=> {
         if(bankAccounts && bankAccounts.user_inputs){
             this.isLoaded = false;
             this.openDialog(bankAccounts)
             this.bankAccounts = bankAccounts;
          }else{
            this.isLoaded = false;
            this.dapiDataService.updateUserAccounts(bankAccounts)
            if(this.inputData){
              this.router.navigate(['/initate-transfer', this.bank_id,this.token,this.inputData]);
            }else{
              this.router.navigate(['/initate-transfer', this.bank_id,this.token]);
            }
            
          }
      },(error) => {
         let errorDetails ={}
         this.dapiErrorDetails = error.error;
         if(error.status==504){
          this.errorModelComponent(error);
          errorDetails = error;
         }else{
          this.errorModelComponent(this.dapiErrorDetails);
          errorDetails = error.error;
         }
         this.isLoaded= false;
         if(this.inputField.action){
           errorDetails['action'] = this.inputField.action.toUpperCase();
          }
          errorDetails['bank_id']= this.inputField.bank_id ? this.inputField.bank_id : '';
          errorDetails['account_number']= this.inputField.account_number ? this.inputField.account_number : '';
          errorDetails['amount']= this.inputField.amount ? this.inputField.amount : '';
          errorDetails['iban']= ''
          errorDetails['note']= this.inputField.note ? this.inputField.note : '';
          errorDetails['allow_ui_flow'] = this.inputField.allow_ui_flow =="NO" ? 'NO' : "YES"
          errorDetails['status'] = 'FAILED',
          errorDetails["event_name"] = "TRANSFER";
          this.postMessage(errorDetails)
          //window.parent.postMessage(JSON.stringify(errorDetails),'*')
         //this.router.navigate(['/dapi_bank_list',this.token,this.inputData])
      })  
  }

  errorModelComponent(bankAccounts): void {
    const dialogRef = this.dialog.open(ErrorModelComponent, {
      width: '362px',
      data: {bankAccounts},
    });

    dialogRef.afterClosed().subscribe(result => {
       if(result){
         this.getBankAccounts(result, '');
       }else{
         if(this.inputData){
          this.router.navigate(['/initate-transfer', this.bank_id,this.token,this.inputData]);
        }else{
          this.router.navigate(['/initate-transfer', this.bank_id,this.token]);
        }
       }
    });
  }

  openDialog(data): void {
    const dialogRef = this.dialog.open(DapiModalComponent, {
      width: 'auto',
      data: {data},
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.confirmOTP(result)
      }else{
        if(this.inputData){
          this.router.navigate(['/initate-transfer', this.bank_id,this.token,this.inputData]);
        }else{
          this.router.navigate(['/initate-transfer', this.bank_id,this.token]);
        }
      }
    });
  }
  
  confirmOTP(data){
    this.bankAccounts.user_inputs = data.user_inputs;
    this.getBankAccount(this.token,this.bank_id,this.bankAccounts)
  }

  getBankAccount(auth_token,bank_id,payload){
     this.isLoaded = true;
     this.dapiService.getDapiBankListByIDMFA(bank_id,auth_token,payload).subscribe((bankAccounts)=> {
      if(bankAccounts){
        this.isLoaded= false;
        this.dapiDataService.updateUserAccounts(bankAccounts)
        if(this.inputData){
          this.router.navigate(['/initate-transfer', this.bank_id,this.token,this.inputData]);
        }else{
          this.router.navigate(['/initate-transfer', this.bank_id,this.token]);
        }
      }
   },(error) => {
      this.isLoaded= false;
      let errorDetails ={}
      if(error.status==504){
        this.errorModelComponent(error);
        errorDetails = error;
      }else{
        this.errorModelComponent(error.error);
        errorDetails = error.error;
      }
      if(this.inputField.action){
        errorDetails['action'] = this.inputField.action.toUpperCase();
      }
      errorDetails['allow_ui_flow'] = this.inputField.allow_ui_flow =="NO" ? 'NO' : "YES"
      //window.parent.postMessage(JSON.stringify(errorDetails),'*')
      this.postMessage(errorDetails)
   })  
}

cancel(){
  if(this.inputData){
    this.router.navigate(['/dapi_bank_list',this.token,this.inputData])
  }else{
    this.router.navigate(['/dapi_bank_list',this.token])
  }
  
}

loadDapi(){
  this.dapiDataService.loadDapiWidgetForLink(true);
  if(this.inputData){
    this.router.navigate(['/dapi_bank_list',this.token,this.inputData])
  }else{
    this.router.navigate(['/dapi_bank_list',this.token])
  }
}

  // Ideal implementation
  postMessage(message) {
    window.parent.postMessage(JSON.stringify(message), "*"); // Exisiting calls

    // // 1. Relay the event back to the host application
    if (window["webkit"]) {
      window["webkit"].messageHandlers.widgetEventCallback.postMessage(message);
    } // iOS support

    if (window["Android"]) {
      // Object Android is available in the global namespace of the host frame
      window["Android"].widgetEventCallback(JSON.stringify(message));
    }
  }
}
