import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DapiDataService {

private  saveUserAccounts= new BehaviorSubject<any>({});  
          getUserAccounts = this.saveUserAccounts.asObservable();
private   loadDapiWidget= new BehaviorSubject<boolean>(false);  
          loadDapiWidgetForRelink = this.loadDapiWidget.asObservable();          
          
                               
  constructor() { }

 updateUserAccounts(object) {  
    this.saveUserAccounts.next(object);  
   }

 
loadDapiWidgetForLink(status) {  
      this.loadDapiWidget.next(status);  
     }   



}
