import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  ViewChild,
} from "@angular/core";

import { DapiService } from "../../services/dapi.service";

import { ActivatedRoute, ParamMap, Router } from "@angular/router";

import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";

import { MatSnackBar } from "@angular/material/snack-bar";

import * as moment from "moment";
import { DapiModalComponent } from "../dapi-modal/dapi-modal.component";
import { ConfirmModalComponent } from "../confirm-modal/confirm-modal.component";
import { DapiDataService } from "src/app/services/dapi-data.service";
import { ErrorModelComponent } from "../error-model/error-model.component";
import { Subscription } from "rxjs";

@Component({
  selector: "app-dapi-bank",
  templateUrl: "./dapi-bank.component.html",
  styleUrls: ["./dapi-bank.component.scss"],
})
export class DapiBankComponent implements OnInit, OnDestroy {
  public displayedColumns: any = [];
  public bankList: any = [];
  public configuration: any;
  public auth_token: string;
  public dapiConnectionLayerFailed: boolean = false;
  public configurationMissing: boolean = false;
  public isLoaded: boolean = true;
  public linkBankObject: any;
  public isCoolDownTime: boolean = false;
  public dapi: any;
  public errorMessage: string = "";
  public dapiError: any;
  public dapiErrorDetails: any;
  public inputData: any;
  public inputToken: any;
  public bankAccounts: any = [];
  private _subscription: Subscription = new Subscription();
  // dataSource = new MatTableDataSource(this.bankList);

  constructor(
    public dapiService: DapiService,
    public dapiDataService: DapiDataService,
    private router: Router,
    public _route: ActivatedRoute,
    public dialog: MatDialog,
    public _nc: MatSnackBar
  ) {}

  ngOnInit() {
    this._route.paramMap.subscribe((params: ParamMap) => {
      this.auth_token = params.get("auth_token");
      this.inputToken = params.get("token");
      if (this.inputToken) {
        this.inputData = JSON.parse(atob(params.get("token")));
      } else {
        this.inputData = {};
      }
      this.inputData.note = this.inputData.note ? this.inputData.note : "";
      if (this.inputData.note.length > 500) {
        this.inputData.note = this.inputData.note.substring(0, 500);
      }
      this.getConfiguration(this.auth_token);
    });
    this._subscription.add(
      this.dapiDataService.loadDapiWidgetForRelink.subscribe((res) => {
        if (res) {
          this.isLoaded = true;
          this.getConfiguration(this.auth_token);
          this.loadDapi("");
        }
      })
    );
    this.isLoaded = true;
    this.displayedColumns = [
      "bank_name",
      "branch_name",
      "sort_code",
      "address",
      "de_link",
      "transfer",
    ];
    

  }

  checkWidget() {
    let bank_id = this.inputData.bank_id ? this.inputData.bank_id : "";
    if (
      this.inputData.action &&
      this.inputData.action.toLowerCase() == "transfer_funds"
    ) {
      this.getDapiLinkedBankListforTransferFund(this.auth_token, bank_id);
    } else if (
      this.inputData.action &&
      this.inputData.action.toLowerCase() == "link_bank"
    ) {
      this.loadDapi(bank_id);
    } else {
      if (
        this.inputData.allow_ui_flow &&
        this.inputData.allow_ui_flow.toLowerCase() == "no"
      ) {
        let errorPayload = {};
        errorPayload["action"] = this.inputData.action.toUpperCase();
        errorPayload["allow_ui_flow"] = this.inputData.allow_ui_flow
          ? this.inputData.allow_ui_flow.toUpperCase()
          : "";
        errorPayload["status"] = "FAILED";
        errorPayload["bank_id"] = this.inputData.bank_id
          ? this.inputData.bank_id
          : "";
        errorPayload["status_description"] = "Action value received is invalid";
        //window.parent.postMessage(JSON.stringify(errorPayload),'*')
        this.postMessage(errorPayload);
        errorPayload["allow_ui_flow"] =
          this.inputData.allow_ui_flow.toLowerCase();
        errorPayload["status_reason"] = "Action value received is invalid";
        this.errorModelComponent(errorPayload, "bank");
      } else {
        this.getDapiLinkedBankList(this.auth_token);
      }
    }
  }

  getWidgetToken() {
    this._subscription.add(
      this.dapiService.getToken().subscribe(
        (config) => {
          if (config) {
            this.auth_token = config.token;
            this.getConfiguration(config.token);
            this.getDapiLinkedBankList(config.token);
            this.isLoaded = false;
          }
        },
        (error) => {
          this.isLoaded = false;
        }
      )
    );
  }

  openDialog(data, type?): void {
    const dialogRef = this.dialog.open(DapiModalComponent, {
      width: "auto",
      data: { data },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (type == "bank") {
          this.confirmOTP(result);
        } else {
          this.isLoaded = true;
          this.linkedBank(result);
        }
      }
    });
  }

  confirmOTP(data) {
    this.bankAccounts.user_inputs = data.user_inputs;
    this.getBankAccountForTransfer(
      this.auth_token,
      this.inputData.bank_id,
      this.bankAccounts
    );
  }

  getBankAccountForTransfer(auth_token, bank_id, payload) {
    this.isLoaded = true;
    this._subscription.add(
      this.dapiService
        .getDapiBankListByIDMFA(bank_id, auth_token, payload)
        .subscribe(
          (bankAccounts) => {
            if (bankAccounts) {
              this.isLoaded = false;
              this.dapiDataService.updateUserAccounts(bankAccounts);
              if (this.inputToken) {
                this.router.navigate([
                  "/initate-transfer",
                  this.inputData.bank_id,
                  this.auth_token,
                  this.inputToken,
                ]);
              } else {
                this.router.navigate([
                  "/initate-transfer",
                  this.inputData.bank_id,
                  this.auth_token,
                ]);
              }
            }
          },
          (error) => {
            this.isLoaded = false;
            let errorDetails = {};
            if (error.status == 504) {
              this.errorModelComponent(error);
              errorDetails = error;
            } else {
              this.errorModelComponent(error.error);
              errorDetails = error.error;
            }
            errorDetails["action"] = this.inputData.action.toUpperCase();
            errorDetails["status"] = "FAILED";
            errorDetails["allow_ui_flow"] =
              this.inputData.allow_ui_flow == "NO" ? "NO" : "YES";
            // window.parent.postMessage(JSON.stringify(errorDetails),'*')
            this.postMessage(errorDetails);
          }
        )
    );
  }

  openConfirmDialog(bank_object): void {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: "362px",
      data: { bank_object },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.delinkBank(result.bank_id);
      }
    });
  }

  resetCoolDown() {
    this.isCoolDownTime = false;
    this.getDapiLinkedBankList(this.auth_token);
  }

  viewCoolDownTime(selected_bank_account) {
    this.isCoolDownTime = true;
    this.linkBankObject = selected_bank_account;
    this.errorModelComponent(this.linkBankObject);
    this.isCoolDownTime = false;
  }

  loadDapi(bank_id: string) {
    this.isLoaded = true;
    if (this.configuration.app_key) {
      var handler = this.dapi.create({
        environment: this.configuration.environment,
        appKey: this.configuration.app_key,
        bankID: bank_id,
        countries: this.configuration.bank_countries,
        isExperimental: false,
        onSuccess: (d) => {
          this.dialog.closeAll();
          let bank_payload = {
            connection_id: d.connectionID,
            user_secret: d.userSecret,
            access_code: d.accessCode,
          };
          this.linkedBank(bank_payload);
        },
        onFailure: (e) => {
          this.setErrorStatus(e);
          //window.parent.postMessage(JSON.stringify(e),window.origin)
          this.isLoaded = false;
        },

        onReady: (r) => {
          console.log("Ready!"), handler.open();
          this.isLoaded = false;
          this.getDapiLinkedBankList(this.auth_token);
        },
      });
    } else {
      this.configurationMissing = true;
      this.isLoaded = false;
    }
  }

  setErrorStatus(err) {
    const resError = { ...err };
    const { code } = resError;
    if (code !== "401") {
      this.dapiError = err;
      this.errorModelComponent(this.dapiError);
    }
    return;
  }

  errorModelComponent(bankAccounts, type?): void {
    const dialogRef = this.dialog.open(ErrorModelComponent, {
      width: "362px",
      data: { bankAccounts },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      } else {
        this.dapiError = "";
        this.dapiErrorDetails = "";
      }
      if (type == "bank") {
        this.getDapiLinkedBankList(this.auth_token);
      }
    });
  }

  linkedBank(bank_payload) {
    this.isLoaded = true;
    this._subscription.add(
      this.dapiService
        .postDapiBankLink(bank_payload, this.auth_token)
        .subscribe(
          (bank_res) => {
            this.linkBankObject = bank_res.bank;
            this.errorMessage = "";
            if (bank_res.bank) {
              this.isCoolDownTime =
                this.linkBankObject.cooldown_period.value > 0 ? true : false;
            }
            if (bank_res) {
              if (bank_res.user_inputs) {
                bank_payload["user_inputs"] = {};
                bank_payload["operation_id"] = bank_res.operation_id;
                bank_payload.user_inputs = bank_res.user_inputs;
                this.openDialog(bank_payload);
              } else {
                this._nc.open("Bank Linked Successfully", "", {
                  duration: 5000,
                  verticalPosition: "top",
                });
                let bank_list = {};
                bank_list = bank_res.bank;
                delete bank_list["created"];
                delete bank_list["modified"];
                delete bank_list["unauthorized"];
                bank_list["action"] = this.inputData.action
                  ? this.inputData.action.toUpperCase()
                  : "";
                bank_list["allow_ui_flow"] =
                  this.inputData.allow_ui_flow == "NO" ? "NO" : "YES";
                bank_list["status"] = "SUCCESS";
                bank_list["event_name"] = "LINK_BANK_SUCCESS";
                //window.parent.postMessage(JSON.stringify(bank_list),'*')
                this.postMessage(bank_list);
                this.getDapiLinkedBankList(this.auth_token);
              }
              this.isLoaded = false;
            }
          },
          (error) => {
            //this.dapiError = error.error;
            let errorDetails = {};
            errorDetails = error.error;
            errorDetails["action"] = this.inputData.action
              ? this.inputData.action.toUpperCase()
              : "";
            errorDetails["allow_ui_flow"] =
              this.inputData.allow_ui_flow == "NO" ? "NO" : "YES";
            errorDetails["bank_id"] = this.inputData.bank_id
              ? this.inputData.bank_id
              : "";
            errorDetails["account_number"] = this.inputData.account_number
              ? this.inputData.account_number
              : "";
            errorDetails["amount"] = this.inputData.amount
              ? this.inputData.amount
              : "";
            errorDetails["event_name"] = "LINK_BANK_FAILED";
            this._nc.open(
              "Authentication is not successful, Please try again.",
              "",
              { duration: 5000, verticalPosition: "top" }
            );
            //window.parent.postMessage(JSON.stringify(errorDetails),window.origin)
            this.postMessage(errorDetails);
            this.getDapiLinkedBankList(this.auth_token);
            this.isLoaded = false;
          }
        )
    );
  }

  delinkBank(bank_id) {
    this.isLoaded = true;
    this._subscription.add(
      this.dapiService.delinkDapiBank(bank_id, this.auth_token).subscribe(
        (res) => {
          this.isLoaded = false;
          if (res) {
            this._nc.open("Bank Delinked Successful", "", {
              duration: 5000,
              verticalPosition: "top",
            });
            let delinkObject = {};
            delinkObject["action"] = this.inputData.action
              ? this.inputData.action.toUpperCase()
              : "";
            delinkObject["allow_ui_flow"] =
              this.inputData.allow_ui_flow == "NO" ? "NO" : "YES";
            delinkObject["bank_id"] = bank_id;
            delinkObject["status"] = "SUCCESS";
            delinkObject["event_name"] = "DELINK_SUCCESS";
            delinkObject["status_description"] = "Bank Delinked Successful";
            //window.parent.postMessage(JSON.stringify(delinkObject),'*')
            this.postMessage(delinkObject);
            this.getDapiLinkedBankList(this.auth_token);
          }
        },
        (error) => {
          //this.dapiErrorDetails = error.error;
          if (error.status == 504) {
            this.errorModelComponent(error);
          } else {
            this.errorModelComponent(this.dapiErrorDetails);
          }
          let errorDetails = {};
          errorDetails = error.error;
          errorDetails["action"] = this.inputData.action
            ? this.inputData.action.toUpperCase()
            : "";
          errorDetails["allow_ui_flow"] =
            this.inputData.allow_ui_flow == "NO" ? "NO" : "YES";
          errorDetails["bank_id"] = bank_id;
          errorDetails["event_name"] = "DELINK_FAILED";
          errorDetails["account_number"] = this.inputData.account_number
            ? this.inputData.account_number
            : "";
          errorDetails["amount"] = this.inputData.amount
            ? this.inputData.amount
            : "";
          //window.parent.postMessage(JSON.stringify(errorDetails),'*')
          this.postMessage(errorDetails);
          this.isLoaded = false;
        }
      )
    );
  }

  getDapiLinkedBankList(token) {
    this.isLoaded = true;
    this._subscription.add(
      this.dapiService.getDapiBankList(token).subscribe(
        (linked_bank_list) => {
          if (linked_bank_list) {
            this.bankList = linked_bank_list;
            this.errorMessage = "";
            for (let bank of this.bankList) {
              let end_date = new Date(bank.cooldown_finished_at).toLocaleString(
                "en-US"
              );
              let end = moment(end_date); // datetime from cooldown_finished_at
              let start = moment(); // current datetime
              let hoursDiff = moment.duration(end.diff(start)).asHours();
              let minDiff = moment.duration(end.diff(start)).asMinutes();
              let secDiff = moment.duration(end.diff(start)).asSeconds();
              if (Math.round(minDiff) > 0) {
                bank["coldown_active"] = true;
                if (Math.round(hoursDiff) <= 0) {
                  bank["coldown_time"] = Math.round(minDiff) + ": MIN";
                } else {
                  bank["coldown_time"] = Math.round(hoursDiff) + " : HRS";
                }
              } else {
                bank["coldown_active"] = false;
              }
            }
            this.isLoaded = false;
          }
        },
        (error) => {
          this.errorMessage = error.error.detail;
          let errorDetails = {};
          errorDetails = error.error;
          errorDetails["action"] = this.inputData.action
            ? this.inputData.action.toUpperCase()
            : "";
          errorDetails["allow_ui_flow"] =
            this.inputData.allow_ui_flow == "NO" ? "NO" : "YES";
          errorDetails["bank_id"] = this.inputData.bank_id
            ? this.inputData.bank_id
            : "";
          errorDetails["account_number"] = this.inputData.account_number
            ? this.inputData.account_number
            : "";
          errorDetails["amount"] = this.inputData.amount
            ? this.inputData.amount
            : "";
          errorDetails["event_name"] = "LINK_BANK_FAILED";
          //window.parent.postMessage(JSON.stringify(errorDetails),'*')
          this.postMessage(errorDetails);
          this._nc.open(error.error.detail, "", {
            duration: 5000,
            verticalPosition: "top",
          });
          this.isLoaded = false;
        }
      )
    );
  }

  getDapiLinkedBankListforTransferFund(token, bank_id?) {
    this.isLoaded = true;
    let callbackPayload = {};
    this.errorMessage = " ";
    callbackPayload["action"] = this.inputData.action
      ? this.inputData.action.toUpperCase()
      : "";
    callbackPayload["allow_ui_flow"] =
      this.inputData.allow_ui_flow == "NO" ? "NO" : "YES";
    callbackPayload["status"] = "FAILED";
    callbackPayload["bank_id"] = this.inputData.bank_id
      ? this.inputData.bank_id
      : "";
    callbackPayload["account_number"] = this.inputData.account_number
      ? this.inputData.account_number
      : "";
    callbackPayload["iban"] = "";
    callbackPayload["note"] = this.inputData.note ? this.inputData.note : "";
    callbackPayload["amount"] = this.inputData.amount
      ? this.inputData.amount
      : "";
    this._subscription.add(
      this.dapiService.getDapiBankList(token).subscribe(
        (linked_bank_list) => {
          if (linked_bank_list) {
            for (let bank of linked_bank_list) {
              let end_date = new Date(bank.cooldown_finished_at).toLocaleString(
                "en-US"
              );
              let end = moment(end_date); // datetime from cooldown_finished_at
              let start = moment(); // current datetime
              let hoursDiff = moment.duration(end.diff(start)).asHours();
              let minDiff = moment.duration(end.diff(start)).asMinutes();
              let secDiff = moment.duration(end.diff(start)).asSeconds();
              if (Math.round(minDiff) > 0) {
                bank["coldown_active"] = true;
                if (Math.round(hoursDiff) <= 0) {
                  bank["coldown_time"] = Math.round(minDiff) + ": MIN";
                } else {
                  bank["coldown_time"] = Math.round(hoursDiff) + " : HRS";
                }
              } else {
                bank["coldown_active"] = false;
              }
            }
            let filterBank = [];
            filterBank = linked_bank_list.filter((result) => {
              return result.bank_id == bank_id;
            });
            if (filterBank.length > 0 && !filterBank[0].coldown_active) {
              this.getBankAccountsForTransfer(this.auth_token, bank_id);
            } else if (filterBank.length > 0 && filterBank[0].coldown_active) {
              callbackPayload["status_description"] =
                "Bank cooldown period is not completed. cooldown period " +
                filterBank[0].cooldown_period.value +
                ", cooldown unit " +
                filterBank[0].cooldown_period.unit;
              //window.parent.postMessage(JSON.stringify(callbackPayload),'*')
              this.postMessage(callbackPayload);
              this.bankList = linked_bank_list;
            } else {
              callbackPayload["allow_ui_flow"] =
                this.inputData.allow_ui_flow == "NO" ? "NO" : "YES";
              callbackPayload["status_description"] = "bank_id not found.";
              callbackPayload["event_name"] = "INITATE_TRANSFER";
              //window.parent.postMessage(JSON.stringify(callbackPayload),'*')
              this.postMessage(callbackPayload);
              if (
                this.inputData.allow_ui_flow &&
                this.inputData.allow_ui_flow.toLowerCase() == "no"
              ) {
                callbackPayload["allow_ui_flow"] =
                  this.inputData.allow_ui_flow.toLowerCase();
                callbackPayload["status_reason"] = "bank_id not found.";
                this.errorModelComponent(callbackPayload, "bank");
              } else {
                this.bankList = linked_bank_list;
              }
            }
            this.isLoaded = false;
          }
        },
        (error) => {
          this.errorMessage = error.error.detail;
          let errorDetails = {};
          errorDetails = error.error;
          errorDetails["action"] = this.inputData.action.toUpperCase();
          errorDetails["status"] = "FAILED";
          errorDetails["allow_ui_flow"] =
            this.inputData.allow_ui_flow == "NO" ? "NO" : "YES";
          errorDetails["bank_id"] = this.inputData.bank_id
            ? this.inputData.bank_id
            : "";
          errorDetails["iban"] = "";
          errorDetails["note"] = this.inputData.note ? this.inputData.note : "";
          errorDetails["account_number"] = this.inputData.account_number
            ? this.inputData.account_number
            : "";
          errorDetails["amount"] = this.inputData.amount
            ? this.inputData.amount
            : "";
          errorDetails["event_name"] = "INITATE_TRANSFER";
          //window.parent.postMessage(JSON.stringify(errorDetails),'*')
          this.postMessage(errorDetails);
          this._nc.open(error.error.detail, "", {
            duration: 5000,
            verticalPosition: "top",
          });
          this.isLoaded = false;
        }
      )
    );
  }

  getBankAccountsForTransfer(auth_token, bank_id) {
    this.dapiService.getDapiBankListByID(bank_id, auth_token).subscribe(
      (bankAccounts) => {
        if (bankAccounts && bankAccounts.user_inputs) {
          this.isLoaded = false;
          this.openDialog(bankAccounts, "bank");
          this.bankAccounts = bankAccounts;
        } else {
          this.isLoaded = false;
          this.dapiDataService.updateUserAccounts(bankAccounts);
          if (this.inputToken) {
            this.router.navigate([
              "/initate-transfer",
              this.inputData.bank_id,
              this.auth_token,
              this.inputToken,
            ]);
          } else {
            this.router.navigate([
              "/initate-transfer",
              this.inputData.bank_id,
              this.auth_token,
            ]);
          }
        }
      },
      (error) => {
        this.isLoaded = false;
        let errorDetails = {};
        if (error.status == 504) {
          this.errorModelComponent(error);
          errorDetails = error;
        } else {
          this.errorModelComponent(error.error);
          errorDetails = error.error;
        }
        errorDetails["action"] = this.inputData.action.toUpperCase();
        errorDetails["allow_ui_flow"] =
          this.inputData.allow_ui_flow == "NO" ? "NO" : "YES";
        errorDetails["bank_id"] = bank_id;
        errorDetails["account_number"] = this.inputData.account_number
          ? this.inputData.account_number
          : "";
        errorDetails["amount"] = this.inputData.amount
          ? this.inputData.amount
          : "";
        errorDetails["note"] = this.inputData.note ? this.inputData.note : "";
        errorDetails["event_name"] = "INITATE_TRANSFER";
        // window.parent.postMessage(JSON.stringify(errorDetails),'*')
        this.postMessage(errorDetails);
      }
    );
  }

  getConfiguration(token) {
    this.isLoaded = true;
    this._subscription.add(
      this.dapiService.getWidgetConfiguration(token).subscribe(
        (config) => {
          if (config) {
            this.configuration = config;
            if (
              this.configuration &&
              this.configuration.environment &&
              this.configuration.bank_countries &&
              this.configuration.app_key
            ) {
              this.configurationMissing = false;
              localStorage.setItem(
                "beneficiary_currency",
                this.configuration.beneficiary_currency
              );
              this.checkWidget();
            } else {
              this._nc.open("You are not allowed to proceed", "", {
                duration: 5000,
                verticalPosition: "top",
              });
              let errorDetails = {
                detail:
                  "tenant settings are missing, You are not allowed to proceed",
              };
              let errMessage = this.setCallbackData(errorDetails);
              //window.parent.postMessage(JSON.stringify(errMessage),'*')
              this.postMessage(errMessage);
              this.isLoaded = false;
            }
            //this.isLoaded = false;
          }
        },
        (error) => {
          this.configuration = {};
          this._nc.open(error.error.detail, "", {
            duration: 5000,
            verticalPosition: "top",
          });
          let errMessage = this.setCallbackData(error.error);
          //window.parent.postMessage(JSON.stringify(errMessage),'*')
          this.postMessage(errMessage);
          this.errorMessage = error.error.detail;
          this.isLoaded = false;
        }
      )
    );
  }

  initateTransfer(bank_id, bank_name) {
    localStorage.setItem("bank_name", bank_name);
    if (this.inputToken) {
      this.router.navigate([
        "/transfer-funds",
        bank_id,
        this.auth_token,
        this.inputToken,
      ]);
    } else {
      this.router.navigate(["/transfer-funds", bank_id, this.auth_token]);
    }
  }

  closeWidget() {
    this.dapiError = "";
    this.dapiErrorDetails = "";
  }

  setCallbackData(err) {
    let errorDetails = {};
    errorDetails["action"] = this.inputData.action
      ? this.inputData.action.toUpperCase()
      : "";
    errorDetails["allow_ui_flow"] =
      this.inputData.allow_ui_flow == "NO" ? "NO" : "YES";
    errorDetails["status_description"] = err.detail;
    errorDetails["status"] = "FAILED";
    errorDetails["bank_id"] = this.inputData.bank_id
      ? this.inputData.bank_id
      : "";
    if (
      this.inputData.action &&
      this.inputData.action.toLowerCase() == "transfer_funds"
    ) {
      errorDetails["account_number"] = this.inputData.account_number
        ? this.inputData.account_number
        : "";
      errorDetails["amount"] = this.inputData.amount
        ? this.inputData.amount
        : "";
      errorDetails["iban"] = "";
      errorDetails["note"] = this.inputData.note ? this.inputData.note : "";
    }

    return errorDetails;
  }

  ngAfterViewInit() {
    this.dapi = window["Dapi"];
    // this.bankList.sort = this.sort;
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  // Ideal implementation
  postMessage(message) {
    window.parent.postMessage(JSON.stringify(message), "*"); // Exisiting calls

    // // 1. Relay the event back to the host application
    if (window["webkit"]) {
      window["webkit"].messageHandlers.widgetEventCallback.postMessage(message);
    } // iOS support

    if (window["Android"]) {
      // Object Android is available in the global namespace of the host frame
      window["Android"].widgetEventCallback(JSON.stringify(message));
    }
  }
}
