import {Component,Input, Inject, ViewChildren, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {DapiService} from '../../services/dapi.service';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-dapi-modal',
  templateUrl: './dapi-modal.component.html',
  styleUrls: ['./dapi-modal.component.scss']
})
export class DapiModalComponent implements OnInit  {
   public dialogData :any;
   accountNumberForm = new FormGroup({
    firstDigit: new FormControl(''),
    secondDigit: new FormControl(''),
    thirdDigit: new FormControl(''),
   });
   formInput = environment.production ? ['input1', 'input2', 'input3','input4','input5','input6'] : ['input1', 'input2', 'input3'];
   public form: FormGroup;
   public error;
   @ViewChildren('formRow') rows: any;
   
   public isClickedTransfer:boolean= false;
   public dapiErrorDetails:any;
   public transferFundResult:any;
   public bank_id:string;
   public token:string;
   public selectedBankAccount:any;
   public note:any;
   public availableBalnaceData:any;
   public isLoaded:boolean=true;

  constructor(
    public dapiService:DapiService,
    public dialogRef: MatDialogRef<DapiModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
     this.dialogData = data.data;
     this.form = this.toFormGroup(this.formInput);
    }

   ngOnInit() { 
  }
  onNoClick(): void {
    this.dialogRef.close(false);
  }


  toFormGroup(elements) {
    const group: any = {};
    elements.forEach(key => {
      group[key] = new FormControl('',[Validators.required,Validators.pattern("^[0-9]*$")]);
    });
    return new FormGroup(group);
   }

   dapiOptSend(){
    let otp = '';
    for (let [key, value] of Object.entries(this.form.value)) {
      otp += value;
    }
    this.dialogData.user_inputs[0].answer = otp;
   }

  keyUpEvent(event, index) {
    let pos = index;

    var charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 48 || charCode > 57)) {
      this.error = 0;
    } else {
      this.form.get(`input${index+1}`).setValue(event.key);
    }
    
    if (event.keyCode === 8 && event.which === 8) {
      pos = index - 1;
    } else {
      pos = index + 1;
    }
    if (pos > -1 && pos < this.formInput.length ) {
      this.rows._results[pos].nativeElement.focus();
    }
   }
  

}


