import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DapiBankComponent } from './components/dapi-bank/dapi-bank.component';
import { ListBankAccountComponent } from './components/list-bank-account/list-bank-account.component';
import { TransferFundComponent } from './components/transfer-fund/transfer-fund.component';

const routes: Routes = [
  { path: 'dapi_bank_list/:auth_token/:token', component: DapiBankComponent },
  { path: 'dapi_bank_list/:auth_token', component: DapiBankComponent },
  { path: 'initate-transfer/:bankId/:auth_token/:token', component: ListBankAccountComponent },
  { path: 'initate-transfer/:bankId/:auth_token', component: ListBankAccountComponent },
  { path: 'transfer-funds/:bankId/:auth_token/:token', component: TransferFundComponent },
  { path: 'transfer-funds/:bankId/:auth_token', component: TransferFundComponent },
  { path: '',   redirectTo: 'dapi_bank_list', pathMatch: 'full' }, // redirect to `first-component`
  { path: '**', component:  DapiBankComponent},
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

 
 }
