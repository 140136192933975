import { Component, OnInit, Input,Output, EventEmitter } from '@angular/core';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { DapiDataService } from 'src/app/services/dapi-data.service';
import { DapiService } from '../../services/dapi.service';
// import { DapiBankComponent } from '../dapi-bank/dapi-bank.component';
import { ErrorModelComponent } from '../error-model/error-model.component';


@Component({
  selector: 'app-dapi-bank-mobile',
  templateUrl: './dapi-bank-mobile.component.html',
  styleUrls: ['./dapi-bank-mobile.component.scss']
})
export class DapiBankMobileComponent implements OnInit {

  public isLoaded:boolean=true;
  public auth_token:string;
  public isCoolDownTime:boolean = false;
  public linkBankObject:any;
  public inputToken:any;
  @Input() bankLists;
  @Input() linkBank;
  // @Input() openConfirmDialog;
  @Input() delink;
  @Output() loadDafi = new EventEmitter<string>();
  @Output() openConfirmDialog = new EventEmitter<string>();
  
  constructor(
    private router:Router,
    public dialog : MatDialog,
    public _route :ActivatedRoute,
    public dapiService:DapiService,
    public dapiDataService:DapiDataService) { }



  ngOnInit() {
    this._route.paramMap.subscribe((params: ParamMap) => {
    this.auth_token = params.get('auth_token');
    this.inputToken = params.get('token');
    })
  }
  initateTransfer(bank_id,bank_name){
    localStorage.setItem("bank_name", bank_name);
    if(this.inputToken){
      this.router.navigate(['/transfer-funds', bank_id,this.auth_token,this.inputToken]);
    }else{
      this.router.navigate(['/transfer-funds', bank_id,this.auth_token]);
    }
    
  }
  loadDapiBank(): void {
    this.loadDafi.next();
  }
  viewCoolDownTime(selected_bank_account){
    this.isCoolDownTime= true;
    this.linkBankObject = selected_bank_account;
    this.errorModelComponent(this.linkBankObject);
    // console.log(this.linkBankObject,'Hello');
    if(this.linkBankObject.coldown_active == false){
      this.isCoolDownTime= false;
    }else{
      this.isCoolDownTime= true;
    }
  }
  errorModelComponent(bankAccounts): void {

    const dialogRef = this.dialog.open(ErrorModelComponent, {
      width: '362px',
      data: {bankAccounts},
    });

    dialogRef.afterClosed().subscribe(result => {
       if(result){
         console.log(result);
       }else{
        
       }
    });
  }

  delinkBankCall(value){
    this.openConfirmDialog.emit(value);
  }
}
