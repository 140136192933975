import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule ,ReactiveFormsModule} from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule, HttpClient} from '@angular/common/http';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTableModule} from '@angular/material/table';
import { AppRoutingModule } from './app-routing.module';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import { AppComponent } from './app.component';
import { DapiBankComponent } from './components/dapi-bank/dapi-bank.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material/button';
import { ListBankAccountComponent } from './components/list-bank-account/list-bank-account.component';
import { TransferFundComponent } from './components/transfer-fund/transfer-fund.component';
import { DapiModalComponent } from './components/dapi-modal/dapi-modal.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { ErrorModelComponent } from './components/error-model/error-model.component';
import { DapiBankMobileComponent } from './components/dapi-bank-mobile/dapi-bank-mobile.component';
import { ListBankAccountMobileComponent } from './components/list-bank-account-mobile/list-bank-account-mobile.component';

@NgModule({
  declarations: [
    AppComponent,
    DapiBankComponent,
    ListBankAccountComponent,
    TransferFundComponent,
    DapiModalComponent,
    ConfirmModalComponent,
    ErrorModelComponent,
    DapiBankMobileComponent,
    ListBankAccountMobileComponent
  ],

  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    RouterModule,
    AppRoutingModule,
    MatToolbarModule,
    MatIconModule,
    MatTabsModule,
    MatListModule,
    HttpClientModule,
    MatButtonModule,
    MatTableModule,
    MatDialogModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatTooltipModule,
    BrowserAnimationsModule,
    
  ],
  providers: [HttpClientModule],
  bootstrap: [AppComponent],
  entryComponents: [DapiModalComponent,ConfirmModalComponent ,ErrorModelComponent]
})
export class AppModule { }
