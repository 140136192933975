import { Injectable} from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { HttpHeaders,HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';

import { catchError, retry } from 'rxjs/operators';

import { environment } from '../../environments/environment';



 @Injectable({
  providedIn: 'root'
})
export class DapiService {
  public environment_url:any;

  constructor(private http: HttpClient) {
    this.environment_url = environment.api_url;
   }

   getWidgetConfiguration(token){
    let httpOptions = {headers: new HttpHeaders({"Authorization":encodeURIComponent(token) })};
     return this.http.get<any>(this.environment_url + 'public/v1/orders/dapi/configuration',httpOptions);
   }


  getToken(){
    return this.http.post<any>(this.environment_url + 'v1/orders/token',{"account_id":'e6b500c7-b37b-46cf-baae-e1b4f43e8749'});
  }


  postDapiBankLink(paylaod,token){
    let httpOptions = {headers: new HttpHeaders({"Authorization":encodeURIComponent(token)})};
    return this.http.post<any>(this.environment_url + 'public/v1/orders/dapi/banks',paylaod,httpOptions);
  }


  getDapiBankListByID(bankId,token){
    let httpOptions = {headers: new HttpHeaders({"Authorization":encodeURIComponent(token) })};
    return this.http.post<any>(this.environment_url + 'public/v1/orders/dapi/banks/'+bankId+'/accounts',{},httpOptions);
  }

  getDapiBankListByIDMFA(bankId,token,payload){
    let httpOptions = {headers: new HttpHeaders({"Authorization":encodeURIComponent(token) })};
    return this.http.post<any>(this.environment_url + 'public/v1/orders/dapi/banks/'+bankId+'/accounts',payload,httpOptions);
  }


  transferFundDapiBankAccount(bankId,token,payload){
    let httpOptions = {headers: new HttpHeaders({"Authorization":encodeURIComponent(token) })};
    return this.http.post<any>(this.environment_url + 'public/v1/orders/dapi/banks/'+bankId+'/accounts/transferFunds',payload,httpOptions);
  }

  getDapiBalanceUserAccount(bankId,token,payload){
    let httpOptions = {headers: new HttpHeaders({"Authorization":encodeURIComponent(token)})};
    return this.http.post<any>(this.environment_url + 'public/v1/orders/dapi/banks/'+bankId+'/accounts/getBalance',payload,httpOptions);
  }


  getDapiBankList(token){
   let httpOptions = {headers: new HttpHeaders({"Authorization":encodeURIComponent(token) })};
    return this.http.get<any>(this.environment_url + 'public/v1/orders/dapi/banks',httpOptions);
 }

 delinkDapiBank(bankId,token){
  let httpOptions = {headers: new HttpHeaders({"Authorization":encodeURIComponent(token) })};
   return this.http.delete<any>(this.environment_url + 'public/v1/orders/dapi/banks/'+bankId,httpOptions);
}

   

  getTenenatId(card_id,lut_token,source_id){
    return this.http.get<any>(this.environment_url + 'public/v1/cards/'+card_id+'/getTenant?source_id='+source_id+'&lut='+encodeURIComponent(lut_token));
  }

   handleError(error: HttpErrorResponse) {

    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);

    } else {
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    return throwError(error);
  }

}
