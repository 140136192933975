import { Component, OnInit, Input,Output, EventEmitter } from '@angular/core';
import {Location} from '@angular/common';
import { Router,ActivatedRoute, ParamMap } from '@angular/router';
import { ListBankAccountComponent } from '../list-bank-account/list-bank-account.component';
import { DapiService } from '../../../../src/app/services/dapi.service';
import { DapiDataService } from '../../../app/services/dapi-data.service';
import { MatDialog } from '@angular/material/dialog';
// import { Router,ActivatedRoute, ParamMap } from '@angular/router';


@Component({
  selector: 'app-list-bank-account-mobile',
  templateUrl: './list-bank-account-mobile.component.html',
  styleUrls: ['./list-bank-account-mobile.component.scss']
})
export class ListBankAccountMobileComponent extends ListBankAccountComponent {

  @Output() loadDafi = new EventEmitter<string>();
  
  constructor(router: Router, _route: ActivatedRoute,
     dapiService:DapiService,
     dapiDataService: DapiDataService,
     dialog: MatDialog){
     super(router,_route,dapiService,dapiDataService,dialog);
  }

  clickChildTransferFund(elem){
    this.clickTransferFund(elem);
  }
  loadDapiBank(): void {
    // console.log(this.token);
    this.loadDafi.next();
    // this.dapiDataService.loadDapiWidgetForLink(true);
    // this.router.navigate(['/dapi_bank_list',this.token])
  }
}
