import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { DapiService } from "../../services/dapi.service";
import { DapiDataService } from "../../services/dapi-data.service";
import { DapiModalComponent } from "../dapi-modal/dapi-modal.component";
import { ErrorModelComponent } from "../error-model/error-model.component";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
@Component({
  selector: "app-list-bank-account",
  templateUrl: "./list-bank-account.component.html",
  styleUrls: ["./list-bank-account.component.scss"],
})
export class ListBankAccountComponent implements OnInit, OnDestroy {
  public displayedColumns: any = [];
  public bank_id: string;
  public token: string;
  public selectedBankAccount: any;
  public isClickedTransfer: boolean = false;
  public dataSource = [];
  public balance: any;
  public note: any = '';
  public transferFundResult: any;
  public availableBalnaceData: any;
  public isLoaded: boolean = true;
  public benificaryCurrency: any;
  public bankName: any;
  public dapiErrorDetails: any;
  public inputData: any;
  public inputField: any;
  private _subscription: Subscription = new Subscription();

  constructor(
    private router: Router,
    private _route: ActivatedRoute,
    public dapiService: DapiService,
    public dapiDataService: DapiDataService,
    public dialog: MatDialog
  ) {
    this.displayedColumns = [
      "iban",
      "account_number",
      "account_name",
      "balance",
      "currency",
      "type",
      "transfer_funds",
    ];
  }

  ngOnInit() {
    this._route.paramMap.subscribe((params: ParamMap) => {
      this.token = params.get("auth_token");
      this.inputData = params.get("token");
      if (this.inputData) {
        this.inputField = JSON.parse(atob(params.get("token")));
        this.inputField["amount"] = this.inputField["amount"]
          ? this.inputField["amount"]
          : "";
        this.inputField["note"] = this.inputField["note"]
              ? this.inputField["note"]
              : "";
      } else {
        this.inputField = {};
        this.inputField["amount"] = "";
        this.inputField["note"] = "";
      }
      this.bank_id = params.get("bankId");
      this.benificaryCurrency = localStorage.getItem("beneficiary_currency");
      this.bankName = localStorage.getItem("bank_name");
    });

    this._subscription.add(
      this.dapiDataService.getUserAccounts.subscribe((res) => {
        this.isLoaded = true;
        if (Object.keys(res).length > 0) {
          this.dataSource = res.accounts;
          this.isLoaded = false;
          if (
            this.inputField.action &&
            this.inputField.action.toLowerCase() == "transfer_funds"
          ) {
            let callbackDetails = {};
            callbackDetails["allow_ui_flow"] =
              this.inputField.allow_ui_flow == "NO" ? "NO" : "YES";
            callbackDetails["status"] = "FAILED";
            callbackDetails["action"] = this.inputField.action
              ? this.inputField.action
              : "";
            callbackDetails["bank_id"] = this.inputField.bank_id
              ? this.inputField.bank_id
              : "";
            callbackDetails["account_number"] = this.inputField.account_number
              ? this.inputField.account_number
              : "";
            callbackDetails["amount"] = this.inputField.amount
              ? this.inputField.amount
              : "";
            callbackDetails["note"] = this.inputField.note
              ? this.inputField.note
              : "";
            callbackDetails["iban"] = "";
            this.note = callbackDetails["note"];
            if (this.note.length > 500) {
                this.note = this.note.substring(0, 500);
                callbackDetails["note"]= this.note
            }
            const num = Number(this.inputField.amount);
            if (Number.isNaN(num) || num <= 0) {
                 this.balance = "";
                 this.inputField.amount = ''
             }else{
               this.balance = this.inputField.amount ? this.inputField.amount : "";
             }

            //this.inputField.amount = Number.isNaN(num) || num <0 ? '' : this.inputField.amount
            let filterAccountNumber = [];
            filterAccountNumber = res.accounts.filter((result) => {
              return (
                result.number == this.inputField.account_number ||
                result.iban == this.inputField.account_number
              );
            });
            if (filterAccountNumber.length <= 0) {
              callbackDetails["status_description"] =
                "account_number not found";
               callbackDetails["event_name"] = "INITATE_TRANSFER";
              this.postMessage(callbackDetails);
              //window.parent.postMessage(JSON.stringify(callbackDetails), "*");
              if (
                this.inputField.allow_ui_flow &&
                this.inputField.allow_ui_flow.toLowerCase() == "no" &&
                this.inputField.action &&
                this.inputField.action.toLowerCase() == "transfer_funds"
              ) {
                delete callbackDetails["status"];
                callbackDetails["allow_ui_flow"] =
                this.inputField.allow_ui_flow.toLowerCase();
                callbackDetails["status_reason"] = "account_number not found";
                this.errorModelComponent(callbackDetails);
              }
            } else {
              this.selectedBankAccount = filterAccountNumber[0];
              this.balance = this.inputField.amount
                ? this.inputField.amount
                : "";
              this.isClickedTransfer = true;
              if (
                this.benificaryCurrency !=
                this.selectedBankAccount.currency.code
              ) {
                this.isClickedTransfer = false;
                callbackDetails["status_description"] =
                  "Currency not supported.";
                callbackDetails["iban"] = this.selectedBankAccount.iban;
                callbackDetails["currency"] =
                  this.selectedBankAccount.currency.code;
                //window.parent.postMessage(JSON.stringify(callbackDetails), "*");
                this.postMessage(callbackDetails);
                if (this.inputField.action) {
                  this.errorModelComponent(callbackDetails);
                }
              }

              if (Number.isNaN(num) || num <= 0) {
                callbackDetails["status_description"] =
                  "amount value is invalid.";
                callbackDetails["iban"] = this.selectedBankAccount.iban;
                this.postMessage(callbackDetails);
                //window.parent.postMessage(JSON.stringify(callbackDetails), "*");
                this.balance = "";
              }
            }
          }
        } else {
          if (this.inputData) {
            this.router.navigate([
              "/dapi_bank_list",
              this.token,
              this.inputData,
            ]);
          } else {
            this.router.navigate(["/dapi_bank_list", this.token]);
          }
        }
      })
    );
  }

  clickTransferFund(selectedBankAccountObj) {
    this.isClickedTransfer = true;
    this.selectedBankAccount = selectedBankAccountObj;
    //this.getBalanceUserBankAccount(this.bank_id,this.token)
  }

  openDialog(data): void {
    const dialogRef = this.dialog.open(DapiModalComponent, {
      width: "362px",
      data: { data },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.transferFund(result);
      } else {
        if (this.inputData) {
          this.router.navigate([
            "/initate-transfer",
            this.bank_id,
            this.token,
            this.inputData,
          ]);
        } else {
          this.router.navigate(["/initate-transfer", this.bank_id, this.token]);
        }
      }
    });
  }

  errorModelComponent(bank_object): void {
    if (this.dialog.openDialogs.length == 0) {
      const dialogRef = this.dialog.open(ErrorModelComponent, {
        width: "362px",
        data: { bank_object },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result && result != "back" && result != "close") {
          this.transferFund(result);
        } else if (result == "back") {
          this.isClickedTransfer = false;
          this.transferFundResult = {};
          this.balance = this.inputField.amount ? this.inputField.amount : "";
          this.note = "";
        } else {
          this.transferFundResult = {};
          this.balance = this.inputField.amount ? this.inputField.amount : "";
          this.note = "";
          if (this.inputData) {
            this.router.navigate([
              "/initate-transfer",
              this.bank_id,
              this.token,
              this.inputData,
            ]);
          } else {
            this.router.navigate([
              "/initate-transfer",
              this.bank_id,
              this.token,
            ]);
          }
        }
      });
    }
  }

  getBankAccounts(auth_token, bank_id) {
    this.isLoaded = true;
    this._subscription.add(
      this.dapiService.getDapiBankListByID(bank_id, auth_token).subscribe(
        (config) => {
          if (config) {
            this.dataSource = config.accounts;
            this.isLoaded = false;
          }
        },
        (error) => {
          this.isLoaded = false;
        }
      )
    );
  }
  getBankDetails(auth_token, bank_id) {
    this.isLoaded = true;
    this._subscription.add(
      this.dapiService.getDapiBankList(auth_token).subscribe(
        (bankList) => {
          if (bankList) {
            let filterBank = [];
            filterBank = bankList.filter((result) => {
              return result.bank_id == bank_id;
            });
            if (filterBank.length > 0) {
              this.selectedBankAccount["bank_id"] = filterBank[0].bank_id;
              this.selectedBankAccount["bank_name"] = filterBank[0].bank_name;
            }
            this.isLoaded = false;
          }
        },
        (error) => {
          this.isLoaded = false;
        }
      )
    );
  }

  transferFund(payload_object?) {
    this.isLoaded = true;
    let payload = {};
    if (payload_object) {
      payload = payload_object;
    } else {
      payload = {
        source_dapi_account_id: this.selectedBankAccount.id,
        amount: this.balance,
        note: this.note,
        account_number: this.selectedBankAccount.number,
        iban:this.selectedBankAccount.iban,
      };
    }
    this._subscription.add(
      this.dapiService
        .transferFundDapiBankAccount(this.bank_id, this.token, payload)
        .subscribe(
          (transfer_fund_data) => {
            if (transfer_fund_data.user_inputs) {
              payload["user_inputs"] = transfer_fund_data.user_inputs;
              payload["operation_id"] = transfer_fund_data.operation_id;
              this.openDialog(payload);
            } else {
              this.isClickedTransfer = false;
              this.transferFundResult = transfer_fund_data;
              this.getBankAccounts(this.token, this.bank_id);
              let callbackDetails = {};
              callbackDetails["transferResult"] = this.transferFundResult;
              this.selectedBankAccount["bank_id"] = this.bank_id;
              this.selectedBankAccount["note"] = this.note;
              this.selectedBankAccount["bank_name"] = this.bankName;
              this.selectedBankAccount["user_id"] = this.selectedBankAccount.id;
              this.selectedBankAccount["account_number"] =
                this.selectedBankAccount.number;
              this.selectedBankAccount["iban"] = this.selectedBankAccount.iban;
              this.selectedBankAccount["account_name"] =
                this.selectedBankAccount.currency.name;
              callbackDetails["bank_account_details"] =
                this.selectedBankAccount;
              callbackDetails["action"] = this.inputField.action
                ? this.inputField.action.toUpperCase()
                : "";
              callbackDetails["allow_ui_flow"] =
                this.inputField.allow_ui_flow == "NO" ? "NO" : "YES";
              delete callbackDetails["bank_account_details"].id;
              delete callbackDetails["bank_account_details"].number;
              callbackDetails["event_name"] = this.transferFundResult.result && this.transferFundResult.result.status=='FAILED' ?  "TRANSFER_FAILED" : 'TRANSFER_SUCCESSFUL';
              //window.parent.postMessage(JSON.stringify(callbackDetails), "*");
              this.postMessage(callbackDetails);
              if (
                (this.transferFundResult.order.result.status.toLowerCase() ==
                  "success" ||
                  this.transferFundResult.order.result.status.toLowerCase() ==
                    "done") &&
                !this.isClickedTransfer
              ) {
                this.errorModelComponent(this.transferFundResult.order);
              } else {
                if (this.transferFundResult.result.status_reason) {
                  this.errorModelComponent(this.transferFundResult.result);
                } else {
                  this.errorModelComponent(
                    this.transferFundResult.order.result
                  );
                }
              }
            }
            this.isLoaded = false;
          },
          (error) => {
            //this.dapiErrorDetails = error.error;
            let callbackDetails = {};
            callbackDetails = error.error;
            callbackDetails["allow_ui_flow"] =
              this.inputField.allow_ui_flow == "NO" ? "NO" : "YES";
            callbackDetails["status"] = "FAILED";
            callbackDetails["action"] = this.inputField.action
              ? this.inputField.action.toUpperCase()
              : "";
            callbackDetails["bank_id"] = this.inputField.bank_id
              ? this.inputField.bank_id
              : "";
            callbackDetails["amount"] = this.inputField.amount
              ? this.inputField.amount
              : "";
            callbackDetails["account_number"] = this.inputField.account_number
              ? this.inputField.account_number
              : this.selectedBankAccount.number;
            callbackDetails["iban"] = this.selectedBankAccount.iban;
            callbackDetails["event_name"] = "TRANSFER_FAILED";
            this.postMessage(callbackDetails);
            //window.parent.postMessage(JSON.stringify(callbackDetails), "*");
            if (error.status == 504) {
              this.errorModelComponent(error);
            } else {
              this.errorModelComponent(error.error);
            }

            this.isLoaded = false;
          }
        )
    );
  }

  //  getBalanceUserBankAccount(bank_id,auth_token){
  //   this.isLoaded = true;
  //   this.selectedBankAccount.dapi_account_id='';
  //   this.selectedBankAccount.dapi_account_id = this.selectedBankAccount.id;
  //   this.dapiService.getDapiBalanceUserAccount(bank_id,auth_token,this.selectedBankAccount).subscribe((balance_response)=>{
  //     if(balance_response){
  //        this.availableBalnaceData = balance_response
  //        this.isLoaded = false;
  //     }
  //  },(error) => {
  //     this.isLoaded = false;
  //     this.router.navigate(['/dapi_bank_list',this.token])
  //   })
  //  }

  cancel() {
    if (this.inputData) {
      this.router.navigate(["/dapi_bank_list", this.token, this.inputData]);
    } else {
      this.router.navigate(["/dapi_bank_list", this.token]);
    }
  }

  close() {
    this.isClickedTransfer = false;
    this.dapiErrorDetails = "";
    this.transferFundResult = {};
    //this.getBalanceUserBankAccount(this.bank_id,this.token);
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  loadDapi() {
    this.dapiDataService.loadDapiWidgetForLink(true);
    if (this.inputData) {
      this.router.navigate(["/dapi_bank_list", this.token, this.inputData]);
    } else {
      this.router.navigate(["/dapi_bank_list", this.token]);
    }
  }

   // Ideal implementation
   postMessage(message) {
    window.parent.postMessage(JSON.stringify(message), "*"); // Exisiting calls

    // // 1. Relay the event back to the host application
    if (window["webkit"]) {
      window["webkit"].messageHandlers.widgetEventCallback.postMessage(message);
    } // iOS support

    if (window["Android"]) {
      // Object Android is available in the global namespace of the host frame
      window["Android"].widgetEventCallback(JSON.stringify(message));
    }
  }
}
